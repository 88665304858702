// TradingViewWidget.jsx
import React, { useEffect, useRef, memo } from 'react';

function TradingViewWidget({ activeRoom }) {
  const widgetContentRef = useRef(null);
  const scriptRef = useRef(null); // To keep track of the appended script
  const timeoutRef = useRef(null); // To keep track of the setTimeout

  useEffect(() => {
    if (!activeRoom || !widgetContentRef.current) return;

    // Function to clean up previous script and widget
    const cleanup = () => {
      // Clear any pending timeouts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }

      // Remove the script if it exists
      if (scriptRef.current) {
        scriptRef.current.remove();
        scriptRef.current = null;
      }

      // Clear the widget container's content
      if (widgetContentRef.current) {
        widgetContentRef.current.innerHTML = '';
      }
    };

    // Clean up any existing widget/script before adding a new one
    cleanup();

    const { ticker, type, country } = activeRoom;
    let symbol = ticker;

    const exchangeMap = {
      SE: 'OMXSTO',
      OL: 'OSL',
      DE: 'XETR',
      FI: 'OMXHEX',
      FR: 'EURONEXT',
      DK: 'OMXCOP'
      // US handled separately
    };

    // Candidate exchanges for US stocks:
    const usExchanges = ['NASDAQ', 'NYSE', 'AMEX'];

    // Candidate exchanges for crypto:
    const cryptoExchanges = [
      (t) => `BINANCE:${t}USDT`,  // Binance commonly uses USDT pairs
      (t) => `COINBASE:${t}USD`,  // Coinbase commonly uses USD pairs
      (t) => `KRAKEN:${t}USD`,
      (t) => `BITSTAMP:${t}USD`
    ];

    function transformTicker(ticker, country) {
      let transformed = ticker;
      if (country === 'SE') {
        transformed = transformed.replace('.ST', '').replace('-', '_');
      } else if (country === 'DK') {
        transformed = transformed.replace('.CO', '').replace('-', '_');
      } else if (country === 'FI') {
        transformed = transformed.replace('.HE', '').replace('-', '_');
      } else if (country === 'DE') {
        transformed = transformed.replace('.DE', '').replace('-', '_');
      } else if (country === 'FR') {
        transformed = transformed.replace('.PA', '').replace('-', '_');
      } else if (country === 'OL') {
        transformed = transformed.replace('.OL', '').replace('-', '_');
      } else if (country === 'US') {
        transformed = transformed.replace('-', '_');
      }
      return transformed;
    }

    if (type === 'stock' && country) {
      const cleanTicker = transformTicker(ticker, country);
      if (country === 'US') {
        // Use the first US exchange from the list for now
        const primaryExchange = usExchanges[0];
        symbol = `${primaryExchange}:${cleanTicker}`;
      } else if (exchangeMap[country]) {
        symbol = `${exchangeMap[country]}:${cleanTicker}`;
      }
    } else if (type === 'crypto') {
      // Use the first crypto exchange from the list
      symbol = cryptoExchanges[0](ticker);
    } else if (type === 'index') {
      symbol = `INDEX:${symbol}`;
    }

    // Set a timeout to initialize the widget after 100ms
    timeoutRef.current = setTimeout(() => {
      // Create the widget container div
      const widgetDiv = document.createElement('div');
      widgetDiv.className = 'tradingview-widget-container__widget';
      widgetContentRef.current.appendChild(widgetDiv);

      // Create and configure the TradingView script
      const script = document.createElement('script');
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js';
      script.type = 'text/javascript';
      script.async = true;

      // Configuration object for the TradingView widget
      const config = {
        symbols: [[symbol]],
        chartOnly: false,
        width: '100%',
        height: '100%',  // Fill the parent container's height
        locale: 'en',
        colorTheme: 'dark',
        autosize: true,
        showVolume: false,
        showMA: false,
        hideDateRanges: false,
        hideMarketStatus: false,
        hideSymbolLogo: false,
        scalePosition: 'right',
        scaleMode: 'Normal',
        fontFamily: '-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif',
        fontSize: '10',
        noTimeScale: false,
        valuesTracking: '1',
        changeMode: 'price-and-percent',
        chartType: 'area',
        headerFontSize: 'medium',
        dateRanges: [
          '1d|5',
          '3m|60',
          '12m|1D',
          '60m|1W',
          'all|1M'
        ]
      };

      // Assign the JSON config as text content
      script.textContent = JSON.stringify(config);

      // Append the script to the widget content container
      widgetContentRef.current.appendChild(script);

      // Keep a reference to the script for cleanup
      scriptRef.current = script;
    }, 100);

    // Cleanup function to remove script and widget div, and clear timeout
    return () => {
      cleanup();
    };
  }, [activeRoom]);

  return (
    <div className="tradingview-widget-container flex flex-col h-full">
      <div ref={widgetContentRef} className="tradingview-widget-content h-[40%]" />
      <a href="https://www.tradingview.com/" rel="noopener noreferrer nofollow" target="_blank">
        <span className="text-blue-700">Track all markets on TradingView</span>
      </a>
      <p className="text-center opacity-50 text-vp-c-text mt-2">News & more coming soon</p>
    </div>
  );
}

export default memo(TradingViewWidget);
