import React, { useEffect } from 'react';

const TradingBannerWidget = () => {
    useEffect(() => {
        // Create and append the TradingView script dynamically
        const script = document.createElement('script');
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
        script.async = true;
        script.innerHTML = `
        {
            "symbols": [
                {
                    "proName": "FOREXCOM:SPXUSD",
                    "title": "S&P 500 Index"
                },
                {
                    "proName": "FOREXCOM:NSXUSD",
                    "title": "US 100 Cash CFD"
                },
                {
                    "proName": "BITSTAMP:BTCUSD",
                    "title": "Bitcoin"
                },
                {
                    "description": "OMXS30",
                    "proName": "OMXSTO:OMXS30"
                },
                {
                    "description": "DAX",
                    "proName": "XETR:DAX"
                },
                {
                    "description": "OIL",
                    "proName": "MARKETSCOM:OIL"
                }
            ],
            "showSymbolLogo": false,
            "isTransparent": true,
            "displayMode": "regular",
            "colorTheme": "dark",
            "locale": "en"
        }
        `;
        // Append the script to the container
        const container = document.querySelector('.tradingview-widget-container');
        container.appendChild(script);
    }, []);

    return (
        <div
            className="tradingview-widget-container w-full h-12 flex justify-center items-center"
        >
            <div className="tradingview-widget-container__widget w-full h-full"></div>
        </div>
    );
};

export default TradingBannerWidget;
