import { useState, useEffect } from 'react';

export function useDimensions() {
    const [dimensions, setDimensions] = useState({
        listHeight: 0,
        containerHeight: 0,
    });

    useEffect(() => {
        const updateDimensions = () => {
            const vh = window.innerHeight; // Viewport height
            const topNavHeight = 64; // Header height
            const roomTabsHeight = 56; // Room tabs
            const inputAreaHeight = 100; // Message input area
            const totalFixedHeight = topNavHeight + roomTabsHeight + inputAreaHeight;

            const containerHeight = vh; // Full height of the container
            const listHeight = containerHeight - totalFixedHeight; // Available height for the list

            setDimensions({
                listHeight: Math.max(listHeight, 100), // Ensure minimum usability
                containerHeight,
            });
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);

    return dimensions;
}
