import React, { useState, useEffect, memo } from 'react';
import { TrendingUp, Users, MenuIcon, Bitcoin, Flame } from 'lucide-react';
import { useFetchTickers } from '../hooks/useFetchTickers';
import Flag from 'react-world-flags';

const TrendingItem = memo(
  ({ item, joinRoom }) => {
    const priceChange =
      typeof item.priceChange === 'number' && !isNaN(item.priceChange)
        ? item.priceChange.toFixed(2)
        : null; // Null indicates no priceChange available

    return (
      <button
        onClick={() => joinRoom(item.name, item.ticker, item.country, item.type)}
        className="flex items-center justify-between w-full p-2 rounded bg-vp-c-bg-alt hover:bg-vp-c-bg-elv transition-colors duration-150"
      >
        <div className="flex items-center space-x-2">
          {item.type === 'crypto' && <Bitcoin size={16} className="text-vp-c-text" />}
          {item.country && (
            <Flag code={item.country} style={{ width: 16, height: 16 }} />
          )}
          <span className="font-medium text-vp-c-text">{item.ticker}</span>
        </div>
        <div className="flex items-center space-x-2">
          <div className="flex items-center space-x-1">
            <Users size={12} className="text-vp-c-text" />
            <span className="text-xs text-gray-300">{item.usersOnline}</span>
          </div>
          {priceChange !== null && (
            <span
              className={`text-xs ${
                priceChange >= 0 ? 'text-green-400' : 'text-red-400'
              }`}
            >
              {priceChange >= 0 ? '+' : ''}
              {priceChange}%
            </span>
          )}
        </div>
      </button>
    );
  },
  (prevProps, nextProps) => {
    // Custom equality check to avoid re-rendering if props haven't changed
    return JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item);
  }
);

const TrendingSection = memo(({ title, items, joinRoom }) => (
  <div className="w-full mb-6 bg-vp-c-bg">
    <div className="flex items-center space-x-2 mb-2">
      {title !== "Popular tickers" ? (
        <TrendingUp size={20} className="text-gray-400" />
      ) : (
        <Flame size={20} className="animate-pulseRed" />
      )}
      <h3 className="text-sm font-semibold text-vp-c-text">{title}</h3>
    </div>
    <div className="space-y-1">
      {items
        .filter((item) => !(item.type === 'stock' && !item.country)) // Only include items that meet the condition
        .map((item) => (
          <TrendingItem key={`${item.type}:${item.ticker}`} item={item} joinRoom={joinRoom} />
        ))}
    </div>
  </div>
));

const TrendingRooms = ({ joinRoom, trendingSidebarOpen, setTrendingSidebarOpen }) => {
  const { availableTickers } = useFetchTickers();
  const [stocks, setStocks] = useState(new Map());
  const [cryptos, setCryptos] = useState(new Map());

  const updateData = (prevMap, newData) => {
    const updatedMap = new Map(prevMap);

    newData.forEach((item) => {
      const key = `${item.type}:${item.ticker}`;
      const existingItem = updatedMap.get(key);

      // Update only if data has changed
      if (!existingItem || JSON.stringify(existingItem) !== JSON.stringify(item)) {
        updatedMap.set(key, item);
      }
    });

    return updatedMap;
  };

  useEffect(() => {
    const fetchTrendingData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_SOCKET_URL + "/gainers");
        const data = await response.json();

        const stocksData = data.data?.stocks || [];
        const cryptosData = data.data?.cryptos || [];

        // Update stocks and cryptos using the map structure
        setStocks((prevStocks) =>
          updateData(
            prevStocks,
            stocksData.map((item) => ({
              ...availableTickers.stocks.find((ticker) => ticker.ticker === item.ticker),
              ...item,
            }))
          )
        );

        setCryptos((prevCryptos) =>
          updateData(
            prevCryptos,
            cryptosData.map((item) => ({
              ...availableTickers.crypto.find((ticker) => ticker.ticker === item.ticker),
              ...item,
            }))
          )
        );
      } catch (error) {
        console.error('Failed to fetch market data:', error);
      }
    };

    fetchTrendingData();

    const interval = setInterval(fetchTrendingData, 10000);
    return () => clearInterval(interval);
  }, [availableTickers]);

  // Convert maps to arrays
  const allTickers = [...availableTickers.stocks.values(), ...availableTickers.crypto.values(), ...availableTickers.index.values(), ...availableTickers.commodity.values()];

  // Sort and slice top 5 for each category
  const sortedStocks = Array.from(stocks.values())
    .sort((a, b) => b.priceChange - a.priceChange)
    .slice(0, 3);

  const sortedCryptos = Array.from(cryptos.values())
    .sort((a, b) => b.priceChange - a.priceChange)
    .slice(0, 3);

  // Sort by usersOnline and take the top 5
  const poppinTickers = allTickers
    .filter((ticker) => ticker.usersOnline) // Ensure usersOnline exists
    .sort((a, b) => b.usersOnline - a.usersOnline)
    .slice(0, 5);

  return (
    <>
      <button
        onClick={() => setTrendingSidebarOpen(true)}
        className="md:hidden fixed left-4 top-4 z-30 p-2 rounded bg-vp-c-bg-alt text-vp-c-text"
      >
        <MenuIcon size={20} />
      </button>

      <div
        className={`fixed md:relative left-0 top-0 w-64 
          bg-vp-c-bg p-4
          transform transition-transform duration-200 ease-in-out z-20 mt-5
          ${trendingSidebarOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'}
          max-h-[70vh] overflow-y-auto
        `}
      >
        <div className="flex justify-between items-center mb-4 md:hidden">
          <h3 className="text-sm font-semibold text-vp-c-text">Trending</h3>
          <button onClick={() => setTrendingSidebarOpen(false)} className="text-gray-400">
            ✕
          </button>
        </div>

        <>
          <TrendingSection title="Popular tickers" items={poppinTickers} joinRoom={joinRoom} />
          <TrendingSection title="Stock gainers" items={sortedStocks} joinRoom={joinRoom} />
          <TrendingSection title="Crypto gainers" items={sortedCryptos} joinRoom={joinRoom} />
        </>
      </div>

      {trendingSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10 md:hidden"
          onClick={() => setTrendingSidebarOpen(false)}
        />
      )}
    </>
  );
};


export default TrendingRooms;
