import React, {
    useMemo,
    useState,
    useEffect,
    useCallback,
    useRef,
    useLayoutEffect
} from 'react';
import { VariableSizeList as List } from 'react-window';
import LoginForm from '../auth/LoginForm';
import { Send, ChevronDown, Smile } from 'lucide-react';
import Picker from 'emoji-picker-react';

const DateBreakoff = React.memo(({ date }) => (
    <div className="text-center py-2 text-sm font-semibold text-gray-500">
        {date === new Date().toDateString() ? "Today" : date}
    </div>
));

const Message = React.memo(({ message, currentUser }) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mentionRegex = new RegExp(`(@${currentUser?.alias})`, 'g');

    const renderMessageWithLinks = (text) => {
        const parts = text.split(urlRegex);
        return parts.map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                    >
                        {part}
                    </a>
                );
            }
            const mentionParts = part.split(mentionRegex);
            return mentionParts.map((subPart, subIndex) => {
                if (subPart.match(mentionRegex)) {
                    return (
                        <span
                            key={`${index}-${subIndex}`}
                            className="bg-vp-c-green-soft text-vp-c-green-1 px-0.5 pb-0.5 rounded mr-1"
                        >
                            {subPart}
                        </span>
                    );
                }
                return subPart;
            });
        });
    };

    return (
        <div className="py-1 px-2 transition-colors duration-150 text-left">
            <span className="text-xs text-vp-c-text">
                {new Intl.DateTimeFormat('sv-SE', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                }).format(new Date(message.timestamp))}
            </span>
            <span className="ml-1 font-semibold" style={{ color: message.color }}>
                {message.username}
            </span>
            <span className='text-vp-c-text'>:</span>
            <span className="ml-1 text-vp-c-text" style={{ lineHeight: '1.4' }}>
                {renderMessageWithLinks(message.message)}
            </span>
        </div>
    );
});

function MessageItem({ chunk, index, setItemSize, user }) {
    const itemRef = useRef(null);

    useLayoutEffect(() => {
        if (itemRef.current) {
            const height = itemRef.current.getBoundingClientRect().height;
            setItemSize(index, height);
        }
    }, [index, setItemSize]);

    if (chunk.type === 'date') {
        return (
            <div ref={itemRef} style={{ height: 'auto', whiteSpace: 'normal' }}>
                <DateBreakoff date={chunk.date} />
            </div>
        );
    }

    return (
        <div ref={itemRef} style={{ height: 'auto', whiteSpace: 'normal' }}>
            <Message message={chunk} currentUser={user} />
        </div>
    );
}

export function ChatArea({
    dimensions,
    messages,
    listRef,
    newMessage,
    setNewMessage,
    handleKeyDown,
    handleSendMessage,
    user,
    onLogin,
    activeRoom,
    captchaToken
}) {
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);

    const handleEmojiClick = (emojiData) => {
        const emoji = emojiData.emoji;
        setNewMessage((prev) => prev + emoji);
        setIsEmojiPickerVisible(false);
    };

    useEffect(() => {
        setNewMessage('');
    }, [activeRoom, setNewMessage]);

    // Now we just return messages as they are, plus date separators
    const getMessagesWithDates = useCallback((msgs) => {
        const processedMessages = [];
        let lastMessageDate = null;

        // Create a set to keep track of seen message IDs or unique keys
        const seenMessages = new Set();

        msgs.forEach((message) => {
            // Construct a unique key. 
            // If message.id exists, prefer that:
            // const uniqueKey = message.id; 

            // If no unique ID is available, fall back to a combo of user + timestamp + truncated message text:
            const uniqueKey = `${message.username}-${message.timestamp}-${message.message}`;

            // Check if we've seen this message before
            if (seenMessages.has(uniqueKey)) {
                return; // skip adding this message again
            }
            seenMessages.add(uniqueKey);

            const messageDate = new Date(message.timestamp).toDateString();

            if (messageDate !== lastMessageDate) {
                processedMessages.push({ type: 'date', date: messageDate });
                lastMessageDate = messageDate;
            }

            processedMessages.push({
                ...message,
                type: 'message'
            });
        });

        return processedMessages;
    }, []);

    const chunkedMessages = useMemo(() => getMessagesWithDates(messages), [messages, getMessagesWithDates]);

    const itemHeights = useRef([]);
    const getItemSize = (index) => {
        return itemHeights.current[index] || 30;
    };

    const setItemSize = (index, size) => {
        itemHeights.current[index] = size;
        if (listRef?.current) {
            listRef.current.resetAfterIndex(index, true);
        }
    };

    const handleScroll = (event) => {
        const { scrollOffset } = event;
        const scrollHeight = chunkedMessages.reduce((sum, _, i) => sum + (itemHeights.current[i] || 30), 0);
        const atBottom = scrollHeight - scrollOffset - dimensions.listHeight <= 30;
        setIsAtBottom(atBottom);
    };

    const scrollToBottom = () => {
        if (listRef?.current) {
            listRef.current.scrollToItem(chunkedMessages.length - 1, 'end');
        }
    };

    // For new messages when at bottom
    useEffect(() => {
        if (listRef?.current && isAtBottom) {
            listRef.current.scrollToItem(chunkedMessages.length - 1, 'end');
        }
    }, [listRef, chunkedMessages.length, isAtBottom]);

    useEffect(() => {
        const current = listRef.current;
        const timeoutId = setTimeout(() => {
            if (current) {
                const lastIndex = chunkedMessages.length - 1;
                current.scrollToItem(lastIndex, 'end');
            }
        }, 100);

        return () => clearTimeout(timeoutId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeRoom]);

    const handleTextAreaFocus = () => {
        if (!user) setShowLoginForm(true);
    };

    const handleSendClick = () => {
        if (!user) {
            setShowLoginForm(true);
        } else {
            handleSendMessage();
        }
    };

    const renderMessageChunk = ({ index, style, data }) => {
        const { chunkedMessages, setItemSize, user } = data;
        const chunk = chunkedMessages[index];

        return (
            <div style={style}>
                <MessageItem
                    chunk={chunk}
                    index={index}
                    setItemSize={setItemSize}
                    user={user}
                />
            </div>
        );
    };

    return (
        <div className="flex flex-col h-full">
            <div className="relative flex-grow overflow-hidden bg-vp-c-alt">
                {activeRoom ? (
                    <>
                        <List
                            height={dimensions.listHeight}
                            itemCount={chunkedMessages.length}
                            itemSize={getItemSize}
                            width="100%"
                            ref={listRef}
                            onScroll={handleScroll}
                            className="relative overflow-hidden z-0"
                            overscanCount={5}
                            itemData={{ chunkedMessages, setItemSize, user }}
                        >
                            {renderMessageChunk}
                        </List>
                        {!isAtBottom && (
                            <button
                                onClick={scrollToBottom}
                                className="
                                    absolute 
                                    bottom-20
                                    right-4 
                                    bg-blue-600 
                                    text-white 
                                    rounded-full 
                                    p-2 
                                    shadow-lg 
                                    ease-in-out
                                    hover:bg-blue-700
                                    hover:scale-95
                                    transition-colors 
                                    z-10
                                "
                                aria-label="Scroll to bottom"
                            >
                                <ChevronDown />
                            </button>
                        )}
                    </>
                ) : (
                    <div className="flex items-center justify-center h-full text-vp-c-text">
                        Welcome! Join a ticker room!
                    </div>
                )}

                <div className="flex-shrink-0 p-2 bg-vp-c-bg">
                    {showLoginForm ? (
                        <LoginForm
                            onLogin={onLogin}
                            isOpen={showLoginForm}
                            onClose={() => setShowLoginForm(false)}
                            captchaToken={captchaToken}
                        />
                    ) : (
                        <div className="relative">
                            <div className="flex items-center space-x-2">
                                <div className="flex-1 relative">
                                    <textarea
                                        value={newMessage}
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        onFocus={handleTextAreaFocus}
                                        onKeyDown={handleKeyDown}
                                        placeholder="Type your message..."
                                        maxLength={500}
                                        className="
                                        w-full
                                        resize-none
                                        rounded
                                        bg-vp-c-bg-alt
                                        p-4 pb-4
                                        h-14
                                        focus:outline-none
                                        focus:ring-1 focus:ring-gray-500 focus:border-transparent
                                        text-vp-c-text
                                        placeholder-gray-400
                                    "
                                    />
                                    {newMessage.length > 0 && (
                                        <div className="absolute bottom-1.5 right-1 text-xs text-gray-400">
                                            {newMessage.length}/500
                                        </div>
                                    )}
                                </div>

                                <button
                                    onClick={() => setIsEmojiPickerVisible(!isEmojiPickerVisible)}
                                    className="p-2 mb-1.5 bg-vp-c-yellow-1 text-gray-700 rounded hover:bg-vp-c-yellow-2 transition-colors duration-150"
                                    aria-label="Open Emoji Picker"
                                >
                                    <Smile />
                                </button>

                                <button
                                    onClick={handleSendClick}
                                    className="p-2 mb-1.5 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors duration-150"
                                >
                                    <Send />
                                </button>
                            </div>

                            {isEmojiPickerVisible && (
                                <div className="absolute bottom-16 right-0 z-50">
                                    <Picker
                                        onEmojiClick={handleEmojiClick}
                                        theme="dark"
                                        emojiStyle="native"
                                        lazyLoadEmojis="true"
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>

        </div>
    );
}
