import React from 'react';
import { X, Bitcoin } from 'lucide-react'; // Import the Bitcoin icon from lucide-react
import Flag from 'react-world-flags'; // Ensure correct import of the Flag component

export function RoomListModal({ isOpen, onClose, rooms, activeRoom, joinRoom }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-vp-c-bg-alt rounded-lg shadow-xl w-full max-w-md max-h-[80vh] border border-vp-c-bg-elv overflow-hidden">
        <div className="flex justify-between items-center p-4 border-b border-vp-c-bg-elv">
          <h2 className="text-xl font-semibold text-vp-c-text">Select Room</h2>
          <button onClick={onClose} className="text-vp-c-gray-1 hover:text-vp-c-text">
            <X size={24} />
          </button>
        </div>

        <div className="max-h-64 overflow-y-auto">
          {rooms.length > 0 ? (
            rooms.map((item) => (
              <button
                key={`${item.type}:${item.ticker}`} // Using a combination of item type and ticker as key
                onClick={() => {
                  joinRoom(item.name, item.ticker, item.country, item.type); // Passing additional parameters to joinRoom
                  onClose();
                }}
                className={`w-full text-left p-3 hover:bg-vp-c-bg-soft border-b border-vp-c-bg-elv last:border-b-0 flex justify-between items-center ${activeRoom.name === item.name
                    ? 'bg-vp-c-indigo-soft text-vp-c-indigo-1 border-b-2 border-vp-c-indigo-2'
                    : 'text-vp-c-text'
                  }`}
              >
                <div className="flex items-center">
                  {item.type === 'crypto' ? (
                    <Bitcoin size={20} className="mr-2 text-vp-c-gray-1" />
                  ) : (
                    item.country && typeof item.country === 'string' && item.country.trim() !== '' && (
                      <Flag code={item.country} className="w-5 h-5 mr-2" />
                    )
                  )}
                  <span className="font-semibold text-vp-c-text">{item.ticker}</span>
                </div>
                <div className="text-right">
                  <span className="text-vp-c-gray-1 text-xs">{item.name}</span>
                </div>
              </button>
            ))
          ) : (
            <div className="text-center p-4 text-vp-c-gray-1">No rooms available</div>
          )}
        </div>
      </div>
    </div>
  );
}
