import { useState, useEffect, useCallback } from 'react';

export function useRooms(socket, alias) {
  const [rooms, setRooms] = useState([]); // Rooms as [{ name, ticker, country, type }]
  const [activeRoom, setActiveRoom] = useState(null); // Active room as { name, ticker, country, type }

  useEffect(() => {
    let storedRooms = [];
    let lastActiveRoom = null;
    try {
      // Parse stored data, clear storage if invalid
      storedRooms = JSON.parse(localStorage.getItem('rooms')) || [];
      lastActiveRoom = JSON.parse(localStorage.getItem('lastActiveRoom'));
    } catch (error) {
      console.error('Invalid JSON in storage, clearing rooms:', error);
      localStorage.removeItem('rooms');
      localStorage.removeItem('lastActiveRoom');
    }

    setRooms(storedRooms);

    if (lastActiveRoom && storedRooms.some((room) =>
      room.ticker === lastActiveRoom.ticker &&
      room.type === lastActiveRoom.type)) {
      setActiveRoom(lastActiveRoom);
    } else if (storedRooms.length > 0) {
      setActiveRoom(storedRooms[0]);
    }

    // Only attempt to emit 'joinRoom' if socket is available
    if (lastActiveRoom && alias && socket) {
      socket.emit('joinRoom', {
        ticker: lastActiveRoom.ticker,
        type: lastActiveRoom.type,
        username: alias,
      });
    }

    return () => {
      // Ensure socket exists before calling emit
      if (socket) {
        storedRooms.forEach(({ ticker, type }) =>
          socket.emit('leaveRoom', {
            ticker,
            type,
            username: alias,
          })
        );
      }
    };
  }, [alias, socket]);

  const joinRoom = useCallback(
    (roomName, ticker, country, type) => {
      // Leave the current room if switching rooms
      setActiveRoom((prevActiveRoom) => {
        if (prevActiveRoom && (prevActiveRoom.ticker !== ticker || prevActiveRoom.type !== type)) {
          if (socket) {
            socket.emit('leaveRoom', {
              ticker: prevActiveRoom.ticker,
              type: prevActiveRoom.type,
              username: alias,
            });
          }
        }
        return prevActiveRoom; // Return previous value to avoid immediate state update
      });

      // Always emit the joinRoom event
      if (socket) {
        socket.emit(
          'joinRoom',
          {
            ticker,
            type,
            username: alias,
          },
          (response) => {
            if (response.error) {
              console.error(`Room Error: ${response.error}`);
              return;
            }

            // Update rooms using the functional form to get the latest state
            setRooms((prevRooms) => {
              // Remove the room if it already exists to prevent duplicates
              const updatedRooms = prevRooms.filter(
                (room) => !(room.ticker === ticker && room.type === type)
              );

              // Add the room to the start of the array
              updatedRooms.unshift({ name: roomName, ticker, country, type });

              // Limit the rooms to 10
              if (updatedRooms.length > 10) {
                const roomToLeave = updatedRooms.pop();

                // Emit leaveRoom for the room that's being removed
                if (socket) {
                  socket.emit('leaveRoom', {
                    ticker: roomToLeave.ticker,
                    type: roomToLeave.type,
                    username: alias,
                  });
                }

                // If the room we're removing is the active room, update activeRoom
                setActiveRoom((prevActiveRoom) => {
                  if (
                    prevActiveRoom?.ticker === roomToLeave.ticker &&
                    prevActiveRoom?.type === roomToLeave.type
                  ) {
                    localStorage.removeItem('lastActiveRoom');
                    return null;
                  }
                  return prevActiveRoom;
                });
              }

              localStorage.setItem('rooms', JSON.stringify(updatedRooms));
              return updatedRooms;
            });

            // Update the active room
            setActiveRoom({ name: roomName, ticker, country, type });
            localStorage.setItem(
              'lastActiveRoom',
              JSON.stringify({ name: roomName, ticker, country, type })
            );
          }
        );
      }
    },
    [alias, socket]
  );

  const leaveRoom = useCallback(
    (ticker, type) => {
      // Filter out the room to leave
      const updatedRooms = rooms.filter(
        (room) => room.ticker !== ticker || room.type !== type
      );

      // Update the rooms list in state and localStorage
      setRooms(updatedRooms);
      localStorage.setItem('rooms', JSON.stringify(updatedRooms));

      // If the active room matches the one being left, update activeRoom
      if (activeRoom?.ticker === ticker && activeRoom?.type === type) {
        // If there's another room left, set it as active; otherwise, set to null
        const nextRoom = updatedRooms.length > 0 ? updatedRooms[0] : null;
        setActiveRoom(nextRoom);
        localStorage.setItem('lastActiveRoom', JSON.stringify(nextRoom));
      }

      // Emit the leaveRoom event
      if (socket) {
        socket.emit('leaveRoom', {
          ticker,
          type,
          username: alias,
        });
      }
    },
    [rooms, activeRoom, alias, socket]
  );

  const clearRooms = useCallback(() => {
    rooms.forEach(({ ticker, type }) => {
      if (socket) {
        socket.emit('leaveRoom', {
          ticker,
          type,
          username: alias,
        });
      }
    });

    setRooms([]);
    setActiveRoom(null);
    localStorage.removeItem('rooms');
    localStorage.removeItem('lastActiveRoom');
  }, [rooms, alias, socket]);

  const ensureRoomExists = useCallback(
    (roomName, ticker, country, type) => {
      setRooms((prevRooms) => {
        // Remove the room if it already exists
        const updatedRooms = prevRooms.filter(
          (room) => !(room.ticker === ticker && room.type === type)
        );

        // Add the room to the start of the array
        updatedRooms.unshift({ name: roomName, ticker, country, type });

        // Limit the rooms to 10
        if (updatedRooms.length > 10) {
          const roomToLeave = updatedRooms.pop();

          // Emit leaveRoom for the room that's being removed
          if (socket) {
            socket.emit('leaveRoom', {
              ticker: roomToLeave.ticker,
              type: roomToLeave.type,
              username: alias,
            });
          }

          // Update activeRoom if necessary
          setActiveRoom((prevActiveRoom) => {
            if (
              prevActiveRoom?.ticker === roomToLeave.ticker &&
              prevActiveRoom?.type === roomToLeave.type
            ) {
              localStorage.removeItem('lastActiveRoom');
              return null;
            }
            return prevActiveRoom;
          });
        }

        localStorage.setItem('rooms', JSON.stringify(updatedRooms));
        return updatedRooms;
      });

      // Update the active room
      setActiveRoom({ name: roomName, ticker, country, type });
      localStorage.setItem(
        'lastActiveRoom',
        JSON.stringify({ name: roomName, ticker, country, type })
      );
    },
    [alias, socket]
  );

  return {
    rooms,
    activeRoom,
    joinRoom,
    leaveRoom,
    clearRooms,
    setRooms,
    ensureRoomExists,
  };
}

