import React, { useState } from 'react';
import { Search, Menu, Bitcoin, X } from 'lucide-react'; // Import the Bitcoin icon
import { RoomSearchModal } from './RoomSearchModal';
import { RoomListModal } from './RoomListModal';
import Flag from 'react-world-flags';
import { ShareRoomButton } from '../modals/shareroom';

export function RoomTabs({ rooms, activeRoom, joinRoom, leaveRoom }) {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [isRoomListModalOpen, setIsRoomListModalOpen] = useState(false);

  return (
    <>
      <div className="bg-vp-c-bg-alt rounded border-vp-c-bg-elv p-2 mb-1">
        <div className="flex items-center justify-between">
          {/* Search Button */}
          <button
            onClick={() => setIsSearchModalOpen(true)}
            className="p-2.5 rounded bg-vp-c-indigo-3 text-vp-c-text hover:bg-vp-c-indigo-2 transition-colors duration-300 ease-in-out flex items-center gap-1"
          >
            <Search size={16} />
            <span className="hidden md:inline ">Search Ticker</span>
          </button>
          <ShareRoomButton activeRoom={activeRoom} />
          {/* Room Tabs */}
          <div className="flex-1 flex items-center space-x-2 overflow-x-auto scrollbar-hide">
            {/* Rooms for Mobile (up to 4 rooms) */}
            <div className="flex items-center space-x-2 md:hidden">
              {rooms.slice(0, 2).map((room) => (
                <button
                  key={`${room.type}:${room.ticker}`}
                  onClick={() => joinRoom(room.name, room.ticker, room.country, room.type)}
                  className={`relative px-3 py-2 ml-2 rounded text-sm font-medium transition-colors ease-in-out duration-150 whitespace-nowrap group ${activeRoom?.ticker === room.ticker && activeRoom?.type === room.type
                      ? 'bg-vp-c-selected-room text-white'
                      : 'bg-vp-c-bg-alt text-gray-300 hover:bg-vp-c-bg-elv'
                    }`}
                >
                  <div className="flex items-center">
                    {room.type === 'crypto' ? (
                      <Bitcoin size={20} className="mr-2 text-vp-c-gray-1" />
                    ) : (
                      room.country && typeof room.country === 'string' && room.country.trim() !== '' && (
                        <Flag code={room.country} className="w-5 h-5 mr-2" />
                      )
                    )}
                    {room.ticker.toUpperCase()}
                  </div>
                  {/* X Icon */}
                  <div
                    role="button"
                    tabIndex="0"
                    className="absolute top-0 right-0 p-1 opacity-0 group-hover:opacity-100 group-hover:bg-gray-800 rounded-bl-lg transition-all ease-in-out duration-200 focus:outline-none cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent click from bubbling up to the parent button
                      leaveRoom(room.ticker, room.type);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.stopPropagation();
                        leaveRoom(room.ticker, room.type);
                      }
                    }}
                  >
                    <X
                      size={16}
                      className="text-vp-c-red-1 hover:text-vp-c-red-2"
                    />
                  </div>
                </button>
              ))}

              {/* Menu Button to Open RoomListModal */}
              {rooms.length > 4 && (
                <button
                  onClick={() => setIsRoomListModalOpen(true)}
                  className="p-2 rounded bg-vp-c-bg-alt text-gray-300 hover:bg-vp-c-bg-elv transition-colors ease-in-out duration-150"
                >
                  <Menu size={16} />
                </button>
              )}
            </div>

            {/* Rooms for Desktop (up to 5 rooms) */}
            <div className="hidden md:flex items-center space-x-2">
              {rooms.slice(0, 4).map((room) => (
                <button
                  key={`${room.type}:${room.ticker}`}
                  onClick={() => joinRoom(room.name, room.ticker, room.country, room.type)}
                  className={`relative px-3 py-2 rounded text-sm font-medium transition-colors ease-in-out duration-150 whitespace-nowrap group ${activeRoom?.ticker === room.ticker && activeRoom?.type === room.type
                      ? 'bg-vp-c-selected-room text-white'
                      : 'bg-vp-c-bg-alt text-gray-300 hover:bg-vp-c-bg-elv'
                    }`}
                >
                  <div className="flex items-center">
                    {room.type === 'crypto' ? (
                      <Bitcoin size={20} className="mr-2 text-vp-c-gray-1" />
                    ) : (
                      room.country && typeof room.country === 'string' && room.country.trim() !== '' && (
                        <Flag code={room.country} className="w-5 h-5 mr-2" />
                      )
                    )}
                    {room.ticker.toUpperCase()}
                  </div>
                  {/* X Icon */}
                  <div
                    role="button"
                    tabIndex="0"
                    className="absolute top-0 right-0 p-1 opacity-0 group-hover:opacity-100 group-hover:bg-gray-800 rounded-bl-lg transition-all ease-in-out duration-200 focus:outline-none cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      leaveRoom(room.ticker, room.type);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.stopPropagation();
                        leaveRoom(room.ticker, room.type);
                      }
                    }}
                  >
                    <X
                      size={16}
                      className="text-vp-c-red-1 hover:text-vp-c-red-2"
                    />
                  </div>
                </button>
              ))}

              {/* Menu Button to Open RoomListModal */}
              {rooms.length > 4 && (
                <button
                  onClick={() => setIsRoomListModalOpen(true)}
                  className="p-2 rounded bg-vp-c-bg-alt text-gray-300 hover:bg-vp-c-bg-elv transition-colors ease-in-out duration-150"
                >
                  <Menu size={16} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Room Search Modal */}
      <RoomSearchModal
        isOpen={isSearchModalOpen}
        onClose={() => setIsSearchModalOpen(false)}
        joinRoom={joinRoom}
      />

      {/* Room List Modal */}
      <RoomListModal
        isOpen={isRoomListModalOpen}
        onClose={() => setIsRoomListModalOpen(false)}
        rooms={rooms}
        activeRoom={activeRoom}
        joinRoom={joinRoom}
      />
    </>
  );
}
