import { useState, useEffect, useCallback } from 'react';

export function useSocket(socket, activeRoom) {
    const [messages, setMessages] = useState([]);
    const [users, setUsers] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const sortMessages = useCallback(
        (msgs) => [...msgs].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)),
        []
    );

    const addMessage = useCallback(
        (msg) => {
            setMessages((prev) => sortMessages([...prev, msg]));
        },
        [sortMessages]
    );

    useEffect(() => {
        // If socket is not available, skip the event listener setup
        if (!socket) return;

        const messageHandlers = {
            previousMessages: (msgs) => setMessages(sortMessages(msgs)),
            message: addMessage,
            userList: (usernames) => {
                const filteredUsernames = usernames.filter((username) => username.trim());
                setUsers(filteredUsernames);
            },
            messageError: ({ reason }) => setErrorMessage(reason),
            removeMessages: ({ username }) => {
                setMessages((prev) => prev.filter((msg) => msg.username !== username));
            },
        };

        // Attach event listeners for the active room
        for (const [event, handler] of Object.entries(messageHandlers)) {
            socket.on(event, handler);
        }

        // Cleanup event listeners when the active room changes or unmounts
        return () => {
            for (const event of Object.keys(messageHandlers)) {
                socket.off(event);
            }
        };
    }, [activeRoom, socket, addMessage, sortMessages]);

    return { messages, users, errorMessage, setMessages, setErrorMessage };
}
