import React, { useEffect, useState } from 'react';
import './css/ErrorMessage.css'; // Ensure you have the CSS for the animations

export default function ErrorMessage({ message, duration = 3000, onClear }) {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (message) {
            setVisible(true); // Show the message when `message` is provided
            const hideTimer = setTimeout(() => {
                setVisible(false); // Start slide-out animation
                if (onClear) {
                    setTimeout(onClear, 500); // Notify parent after animation
                }
            }, duration);

            return () => clearTimeout(hideTimer); // Cleanup timer
        }
    }, [message, duration, onClear]);

    if (!message) return null; // Do not render if there's no message

    return (
        <div className={`error-message ${visible ? 'show' : 'hide'}`}>
            <p>{message}</p>
        </div>
    );
}
