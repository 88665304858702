import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ChatRoom from './components/chat/ChatRoom';
import Header from './components/modals/Header';
import { io } from 'socket.io-client';
import { useRooms } from './components/hooks/Rooms';
import RoomWrapper from './components/hooks/RoomWrapper';
import Rules from './components/legal/rules';
import TermsOfService from './components/legal/tos';
import PrivacyPolicy from './components/legal/privacy';


function App() {
    const [user, setUser] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const [socket, setSocket] = useState(null);
    const [captchaToken, setCaptchaToken] = useState(null);

    const siteKey = '6LcHmI8qAAAAAPeg-ag0XE1-9upvdu1LdJrp5Ya3';
    const { rooms, activeRoom, joinRoom, leaveRoom, clearRooms, setRooms, loading } = useRooms(
        socket,
        user?.alias || ''
    );

    // ReCAPTCHA initialization and refresh
    useEffect(() => {
        const loadRecaptcha = () => {
            const executeCaptcha = async () => {
                if (window.grecaptcha) {
                    try {
                        const token = await window.grecaptcha.execute(siteKey, { action: 'global' });
                        setCaptchaToken(token);
                    } catch (error) {
                        console.error('Error fetching ReCAPTCHA token:', error);
                    }
                }
            };
        
            if (!window.grecaptcha) {
                const script = document.createElement('script');
                script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
                script.async = true;
                script.defer = true;
                script.onload = () => setTimeout(executeCaptcha, 500); // Add a slight delay
                document.body.appendChild(script);
            } else {
                executeCaptcha();
            }
        };

        loadRecaptcha();

        const interval = setInterval(() => {
            if (!user) {
                loadRecaptcha();
            }
        }, 2 * 60 * 1000); // Refresh token every 2 minutes if user is not logged in

        return () => clearInterval(interval);
    }, [user]);

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const storedRooms = JSON.parse(localStorage.getItem('rooms') || '[]');

        if (storedUser) {
            setUser(storedUser);
        }

        if (storedRooms.length > 0) {
            setRooms(storedRooms);
        }

        setInitialized(true);
    }, [setRooms]);

    useEffect(() => {
        if (initialized && socket) {
            const lastActiveRoom = JSON.parse(localStorage.getItem('lastActiveRoom'));

            const handleConnect = () => {
                if (lastActiveRoom && lastActiveRoom.name) {
                    joinRoom(lastActiveRoom.name, lastActiveRoom.ticker, lastActiveRoom.country, lastActiveRoom.type);
                }
            };

            socket.on('connect', handleConnect);

            return () => {
                socket.off('connect', handleConnect);
            };
        }
    }, [initialized, socket, joinRoom]);

    useEffect(() => {
        const socketInstance = io(process.env.REACT_APP_SOCKET_URL);

        socketInstance.on('connect', () => {
            console.log('Connected to socket server');
        });

        socketInstance.on('connect_error', (err) => {
            console.error('Socket connection error:', err);
        });

        setSocket(socketInstance);

        return () => {
            socketInstance.disconnect();
            console.log('Socket disconnected');
        };
    }, []);

    useEffect(() => {
        if (user && user.token && socket) {
            socket.auth = { token: user.token };
            socket.connect();
        }
    }, [user, socket]);

    useEffect(() => {
        if (initialized) {
            localStorage.setItem('rooms', JSON.stringify(rooms));
        }
    }, [rooms, initialized]);

    const handleLogin = (userData) => {
        setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));

        if (socket) {
            socket.disconnect();
        }

        const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
            auth: { token: userData.token },
        });

        newSocket.on('connect', () => {
            console.log('Reconnected to socket server after login');
        });

        newSocket.on('connect_error', (err) => {
            console.error('Socket reconnection error:', err);
        });

        setSocket(newSocket);
    };

    const handleLogout = () => {
        if (socket && user) {
            socket.emit('logout', { username: user.alias, rooms: rooms });
        }
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('rooms');
        clearRooms();
    };

    if (!initialized) {
        return (
            <div className="grid place-items-center h-screen bg-[#181818]">
                <div className="text-center">
                    <div 
                        className="animate-spin inline-block w-16 h-16 border-[4px] border-current border-t-transparent text-vp-c-green-2 rounded-full" 
                        role="status" 
                        aria-label="loading"
                    >
                    </div>
                    <p className="mt-4 text-xl font-semibold text-vp-c-text">Loading...</p>
                </div>
            </div>
        );
    }

    return (
        <Router>
            <div className="grid grid-rows-[auto_1fr] min-h-screen bg-vp-c-bg">
                <Header user={user} onLogin={handleLogin} onLogout={handleLogout} captchaToken={captchaToken}/>
                <main className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 p-4 h-[calc(100vh-64px)]">
                    <Routes>
                        <Route path="/rules" element={<Rules />} />
                        <Route path="/tos" element={<TermsOfService />} />
                        <Route path="/privacy" element={<PrivacyPolicy />} />
                        <Route
                            path="/:prefixAndRoom"
                            element={
                                <RoomWrapper
                                    joinRoom={joinRoom}
                                />
                            }
                        />
                        <Route
                            path="/"
                            element={
                                <ChatRoom
                                    user={user || ''}
                                    socket={socket}  // Make sure socket is passed only after initialization
                                    rooms={rooms}
                                    activeRoom={activeRoom}
                                    joinRoom={joinRoom}
                                    leaveRoom={leaveRoom}
                                    onLogin={handleLogin}
                                    loading={loading}
                                    captchaToken={captchaToken}
                                />
                            }
                        />
                    </Routes>
                </main>
            </div>
        </Router>
    );
}

export default App;
