import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="bg-[#181818] text-[#e0e0e0] h-screen flex flex-col overflow-hidden">
      <h1 className="text-3xl font-bold p-4 text-center text-[#3dd68c]">
        Privacy Policy
      </h1>
      <div className="flex-grow overflow-y-auto px-4 py-2">
        <div className="max-w-xl mx-auto space-y-4 pb-16">
          {[
            {
              title: "1. Information We Collect",
              description: (
                <ul className="list-disc pl-6">
                  <li>
                    <strong>Account Information:</strong> Username, email, and any details you provide.
                  </li>
                  <li>
                    <strong>Usage Data:</strong> Information about your access and usage, including IP address and device type.
                  </li>
                  <li>
                    <strong>Third-Party Data:</strong> Basic profile details from services like Google or Facebook if used for login.
                  </li>
                </ul>
              ),
            },
            {
              title: "2. How We Use Your Information",
              description:
                "We use your data to provide, improve, and secure the platform, personalize your experience, and communicate updates or notices.",
            },
            {
              title: "3. How We Share Your Information",
              description:
                "We do not sell your personal information. Data is shared only with service providers or to comply with legal obligations.",
            },
            {
              title: "4. Your Choices and Rights",
              description: (
                <ul className="list-disc pl-6">
                  <li>Access, update, or delete account information in your profile settings.</li>
                  <li>Manage cookies through your browser.</li>
                  <li>
                    Request data deletion by contacting{" "}
                    <a href="mailto:privacy@tradechat.se" className="text-[#3dd68c] underline">
                      privacy@tradechat.se
                    </a>
                    .
                  </li>
                </ul>
              ),
            },
            {
              title: "5. Data Security",
              description:
                "We use industry-standard measures to protect your data but cannot guarantee complete security.",
            },
            {
              title: "6. Data Retention",
              description:
                "We retain data as long as necessary for the Service or legal compliance. Deleted accounts may remain in backups for a limited time.",
            },
            {
              title: "7. International Data Transfers",
              description:
                "Data may be transferred to countries with different protection laws. By using the platform, you consent to these transfers.",
            },
            {
              title: "8. Updates to This Privacy Policy",
              description:
                "Changes to this policy will be communicated through notifications or email. Continued use of Tradechat means acceptance of updates.",
            },
            {
              title: "9. Contact Us",
              description: (
                <p>
                  For questions, email us at{" "}
                  <a href="mailto:privacy@tradechat.se" className="text-[#3dd68c] underline">
                    privacy@tradechat.se
                  </a>
                  .
                </p>
              ),
            },
          ].map((policy, index) => (
            <div key={index} className="bg-[#202020] p-4 rounded-lg">
              <h2 className="text-lg font-semibold text-[#3dd68c] mb-2">
                {policy.title}
              </h2>
              <div className="text-sm">{policy.description}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
