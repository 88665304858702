import React from 'react';

const Rules = () => {
  return (
    <div className="bg-[#181818] text-[#e0e0e0] h-screen flex flex-col overflow-hidden">
      <h1 className="text-3xl font-bold p-4 text-center text-[#3dd68c]">
        Rules
      </h1>
      <div className="flex-grow overflow-y-auto px-4 py-2">
        <div className="max-w-xl mx-auto space-y-4 pb-16">
          {[
            {
              title: "Be Respectful",
              description:
                "Maintain a respectful tone while discussing financial topics. Refrain from personal attacks or inflammatory language.",
            },
            {
              title: "No Harassment",
              description:
                "Do not harass, threaten, or intimidate other users. Avoid sharing explicit or inappropriate content.",
            },
            {
              title: "Avoid Manipulative Practices",
              description:
                "Prohibited: pump-and-dump schemes, price manipulation, or spreading false financial information.",
            },
            {
              title: "Transparency",
              description:
                "Disclose vested interests in financial assets. Avoid unverifiable rumors or speculative claims.",
            },
            {
              title: "No Illegal Activities",
              description:
                "Discussions promoting illegal or unethical behavior are strictly prohibited.",
            },
            {
              title: "Protect Privacy",
              description:
                "Do not share private or sensitive financial or personal information.",
            },
            {
              title: "Follow Guidelines",
              description:
                "Respect moderators' decisions. Repeated violations may lead to account bans.",
            },
          ].map((rule, index) => (
            <div key={index} className="bg-[#202020] p-4 rounded-lg">
              <h2 className="text-lg font-semibold text-[#3dd68c] mb-2">
                {index + 1}. {rule.title}
              </h2>
              <p className="text-sm">{rule.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Rules;
