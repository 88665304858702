import React from 'react';

const TermsOfService = () => {
  return (
    <div className="bg-[#181818] text-[#e0e0e0] h-screen flex flex-col overflow-hidden">
      <h1 className="text-3xl font-bold p-4 text-center text-[#3dd68c]">
        Terms of Service
      </h1>
      <div className="flex-grow overflow-y-auto px-4 py-2">
        <div className="max-w-xl mx-auto space-y-4 pb-16">
          {[
            {
              title: "1. Acceptance of Terms",
              description:
                "By accessing or using Tradechat, you agree to comply with these Terms of Service. If you do not agree, you may not use the Service.",
            },
            {
              title: "2. Eligibility",
              description:
                "Users must be at least 18 years old to access the Service and comply with the financial laws of their jurisdiction.",
            },
            {
              title: "3. Content Guidelines",
              description:
                "You retain ownership of your posts but grant Tradechat a license to display and distribute your content as part of the Service. You are responsible for the accuracy of any financial information shared.",
            },
            {
              title: "4. Prohibited Conduct",
              description:
                "Do not manipulate markets, spread false information, or violate securities or financial laws. Engaging in pump-and-dump schemes is strictly prohibited.",
            },
            {
              title: "5. Content Moderation",
              description:
                "Tradechat reserves the right to remove harmful or violating content and suspend accounts as necessary.",
            },
            {
              title: "6. Liability Disclaimer",
              description:
                'Tradechat is provided "as-is" for informational purposes only. We are not liable for financial losses incurred through the Service.',
            },
            {
              title: "7. Privacy",
              description:
                "Your use of Tradechat is governed by our Privacy Policy, which details how we protect your data.",
            },
            {
              title: "8. Enforcement",
              description:
                "Users found violating these Terms may face content removal, suspension, or reporting to regulatory bodies.",
            },
            {
              title: "9. Governing Law",
              description:
                "These Terms are governed by the laws of Sweden. Disputes will be resolved under the jurisdiction of Sweden.",
            },
            {
              title: "10. Updates to Terms",
              description:
                "We reserve the right to update these Terms. Continued use of the Service constitutes acceptance of the revised Terms.",
            },
          ].map((term, index) => (
            <div key={index} className="bg-[#202020] p-4 rounded-lg">
              <h2 className="text-lg font-semibold text-[#3dd68c] mb-2">
                {term.title}
              </h2>
              <p className="text-sm">{term.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;
