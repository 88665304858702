import { useState, useEffect } from 'react';

export function useError(duration = 3000) {
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (errorMessage) {
            const timeout = setTimeout(() => setErrorMessage(''), duration);
            return () => clearTimeout(timeout);
        }
    }, [errorMessage, duration]);

    const triggerError = (message) => setErrorMessage(message);

    return { errorMessage, triggerError, clearError: () => setErrorMessage('') };
}
