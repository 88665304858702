import React, { useState } from 'react';
import { Share2 } from 'lucide-react';

export function ShareRoomButton({ activeRoom }) {
    const [copied, setCopied] = useState(false);

    const handleShare = () => {
        const baseUrl = window.location.origin;
        const shareUrl =`${baseUrl}/${activeRoom.type}-${activeRoom.ticker}`;

        navigator.clipboard.writeText(shareUrl).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }).catch(err => {
            console.error('Failed to copy: ', err);
        });
    };

    if(activeRoom){
        return (
            
            <button 
                onClick={handleShare} 
                className={`
                    flex items-center px-3 ml-2 py-2.5 rounded-md 
                    transition-all duration-200 ease-in-out
                    ${copied 
                        ? 'bg-vp-c-green-2 text-white' 
                        : 'bg-vp-c-bg-elv hover:bg-vp-c-gray-2 text-vp-c-text'}
                    hover:scale-105 active:scale-95
                `}
                title="Share Room Link"
            >
                <Share2 size={16} />
                <span className="text-sm">
                </span>
            </button>
        );
    }
}