import { useState, useEffect, useCallback } from 'react';

const sortTickers = (tickers) => {
  const allRoomUsersZero = tickers.every((ticker) => (ticker.usersOnline || 0) === 0);
  return tickers.sort((a, b) => {
    if (allRoomUsersZero) {
      return (b.mcap || 0) - (a.mcap || 0);
    }
    return (b.usersOnline || 0) - (a.usersOnline || 0);
  });
};

export function useFetchTickers() {
  const [availableTickers, setAvailableTickers] = useState({
    stocks: [],
    crypto: [],
    index: [],
    commodity: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const mergeData = (currentData, newData) => {
    const mergedData = newData.map((newItem) => {
      const existingItem = currentData.find(
        (item) => item.ticker === newItem.ticker
      );
      return existingItem ? { ...existingItem, ...newItem } : newItem;
    });
    return mergedData;
  };

  const fetchTickers = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(process.env.REACT_APP_SOCKET_URL + "/tickers");

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Merge new data with current data for all ticker types and sort them
      setAvailableTickers((prevTickers) => ({
        stocks: sortTickers(mergeData(prevTickers.stocks, data.data.stocks || [])),
        crypto: sortTickers(mergeData(prevTickers.crypto, data.data.cryptos || [])),
        index: sortTickers(mergeData(prevTickers.index, data.data.indexes || [])),
        commodity: sortTickers(mergeData(prevTickers.commodity, data.data.commodity || [])),
      }));
    } catch (error) {
      console.error('Failed to fetch tickers:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []); // Empty dependency array ensures a stable reference

  useEffect(() => {
    fetchTickers();

    // Set up the interval to fetch tickers every 10 seconds
    const interval = setInterval(() => {
      fetchTickers();
    }, 10000);

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [fetchTickers]); // Include fetchTickers in dependencies

  return {
    availableTickers,
    loading,
    error,
    fetchTickers,
  };
}
