import React, { useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFetchTickers } from '../hooks/useFetchTickers';

function RoomWrapper({
    joinRoom: originalJoinRoom,
}) {
    const { prefixAndRoom } = useParams();
    const navigate = useNavigate();
    const [prefix, roomName] = prefixAndRoom.split('-');
    const { availableTickers, loading: tickersLoading } = useFetchTickers();
    const hasRedirected = useRef(false); // Prevent repeated redirection

    // Memoize joinRoom to prevent unnecessary re-creations
    const joinRoom = useCallback(
        (...args) => originalJoinRoom(...args),
        [originalJoinRoom]
    );

    useEffect(() => {
        if (tickersLoading || !prefix || !roomName || hasRedirected.current) return;

        // Determine the appropriate tickers based on the prefix
        let tickers;
        switch (prefix) {
            case 'stock':
                tickers = availableTickers.stocks;
                break;
            case 'crypto':
                tickers = availableTickers.crypto;
                break;
            case 'index':
                tickers = availableTickers.index;
                break;
            case 'commodity':
                tickers = availableTickers.commodity;
                break;
            default:
                tickers = []; // If the prefix is unknown
        }

        // Find the room based on the roomName (ticker symbol)
        const room = tickers?.find(
            ticker => ticker.ticker.toUpperCase() === roomName.toUpperCase()
        );

        if (room) {
            joinRoom(room.name, room.ticker, room.country, room.type);
            hasRedirected.current = true; // Mark redirection as complete
            navigate('/'); // Navigate back to the homepage
        } else {
            console.log('Room not found');
            hasRedirected.current = true;
            navigate('/'); // Navigate to homepage if room not found
        }
    }, [prefix, roomName, availableTickers, tickersLoading, joinRoom, navigate]);

    if (tickersLoading) {
        return (
            <div className="flex items-center justify-center h-screen bg-[#181818]">
                <div className="text-center">
                    <div
                        className="animate-spin inline-block w-16 h-16 border-[4px] border-current border-t-transparent text-vp-c-green-2 rounded-full"
                        role="status"
                        aria-label="loading"
                    ></div>
                    <p className="mt-4 text-xl font-semibold text-vp-c-text">
                        Loading...
                    </p>
                </div>
            </div>
        );
    }

    return null; // Render nothing when no loading or redirection is needed
}

export default RoomWrapper;
