import React from 'react';
import TradingViewWidget from '../modals/TradingViewWidget';

export function RoomInfo({ activeRoom }) {
    return (
        <>
            {/* TradingView Widget added here */}
            <div className="h-full">
                <TradingViewWidget activeRoom={activeRoom}/>
                <p className="text-center text-vp-c-text opacity-50">News & more coming soon</p>
            </div>
        </>
    );
}
